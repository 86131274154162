#projects {
  background: -webkit-gradient(
    linear,
    0% 0%,
    0% 100%,
    from(#00c2ff),
    to(#d1e3ff)
  );
  position: relative;
  height: 75vh;
  z-index: 2;
}

#projects:before {
  background-color: #0bc4fc;
  position: absolute;
  top: -5vh;
  content: '';
  height: 5vh;
  width: 100%;
  transform: skewY(1.5deg);
  transform-origin: 0 100%;
}

#projects h2 {
  text-align: center;
  text-shadow: 0px 0px 4px #eee;
  font-size: 22px;
  color: #eee;
  letter-spacing: 2px;
  width: 100%;
}

#card-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  perspective: 1400px;
  animation: 1s ease-out 0s 1 slideIn;
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

#projects .card {
  width: 23%;
  margin-top: -17vh;
  transform: rotateX(57deg) scale(0.9);
  transition: 0.5s all ease;
  box-shadow: 0px 20px 50px;
  padding: 13px;
  background-color: rgb(15, 15, 15);
  padding-bottom: 10vh;
}

.navContent {
  color: white;
  font-size: 16px;
  font-weight: 600;
  overflow: auto;
  height: 21vh;
  padding: 5px;
  padding-top: 0px;
  padding-bottom: 14px;
}

.navContent h3 {
  margin: 0px;
  display: inline-block;
  font-size: 14px;
}

.navContent hr {
  margin: 2px 0px;
  margin-right: 10px;
  margin-top: -3px;
}

.navContent .features {
  margin: 0px;
  margin-right: 20px;
  padding-left: 26px;
  font-size: 14px;
}

.navContent .stack {
  font-size: 13px;
  display: grid;
  margin-top: 0px;
  grid-template-columns: 1fr 1fr 1fr;
  padding-left: 21px;
  margin-right: 10px;
}

.navContent .stack li {
  margin-right: 18px;
}

.navContent p {
  margin-top: 0px;
}

.navContent::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.navContent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.navContent::-webkit-scrollbar-thumb {
  background-color: #fa4545;
  border: 1px solid #fa1414;
}

.links {
  position: absolute;
  font-size: 17px;
  color: white;
  width: 92%;
  bottom: 0.7vh;
  background-color: rgb(15, 15, 15);
}

.links hr {
  border-color: rgb(42, 65, 126);
  border-width: 0.6px;
}

.links p {
  margin: 8px 5px;
}

.links img {
  width: 23px !important;
  margin-left: 5px;
  height: 23px !important;
  float: right;
  background-color: white;
}

.links img:hover {
  box-shadow: 1px 1px 1px #ccc !important;
}

.links a {
  color: rgb(116, 206, 241);
  font-weight: 700;
  margin-left: 4px;
}

.links a:hover {
  text-shadow: 0px 0px 3px #eee;
}

#projects .card:hover {
  transform: rotate(0deg) translateY(12vh) scale(1);
  transition: 0.5s all ease;
  z-index: 2;
}

#projects .card:hover > img {
  transform: scale(2.2) translateY(-50px);
  transition: 0.5s all ease;
  transition-delay: 700ms;
}

#projects .card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: 0.5s all ease;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Media Queries */
@media (max-width: 400px) {
  .container {
    width: 100%;
  }

  #projects h1 {
    width: 90%;
  }

  .navContent {
    font-size: 12px;
  }
}

@media (max-width: 880px) {
  #projects {
    height: auto;
  }

  #projects .card {
    width: 90%;
    transform: none;
    margin: 20px;
    height: 100%;
    padding-bottom: 13px;
  }

  #projects .container {
    width: 100%;
    margin: 0;
  }

  #projects .card:hover {
    transform: none;
  }

  #projects .card:hover > img {
    transform: none !important;
  }

  .links {
    position: relative;
    width: 100%;
    bottom: -10px;
  }
}

@media (min-width: 880px) and (max-width: 1320px) {
  #projects .card {
    width: 20vw;
  }

  .links img {
    display: none;
  }

  .card ul {
    font-size: 12px;
  }
}

@media (max-width: 1600px) {
  #projects .card:hover > img {
    transform: scale(2) translateY(-4.2vh);
  }
}

@media (min-width: 2400px) {
  #projects .card {
    height: 650px;
    padding-bottom: 6vh;
  }

  #projects .card:hover {
    transform: translateY(100px) scale(0.8);
  }

  #projects .card:hover > img {
    transform: scale(2) translateY(-60px);
  }
}
