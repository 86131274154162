body {
  font: 16px/1.5 'Raleway', Helvetica, sans-serif;
  margin: 0px;
  font-variant-ligatures: no-common-ligatures;
}

.container {
  margin: auto;
  width: 70%;
  z-index: 2;
}

html {
  scroll-behavior: smooth;
}

h1.underline {
  font-size: 40px;
  color: #eee;
  letter-spacing: 2px;
  position: relative;
  left: 10%;
  width: 90%;
}

h1.underline:after {
  content: '';
  position: absolute;
  width: 85px;
  height: 1px;
  bottom: 0;
  left: 38px;
  border-bottom: 2px solid #006080;
}

a {
  color: white;
}

a:visited {
  color: rgb(228, 180, 243);
}
